/* import local font */
@font-face {
  font-family: 'Aldo the Apache';
  src: url('./assets/fonts/AldotheApache.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 

@import './theme.css';

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

/* remove all p styles */
p {

  color: var(--text-color);
}

/* hide scrollbar from all pages. */
::-webkit-scrollbar {
  display: none;
}


#background {
fill: var(--background-color);
}

path.banner-text.cls-28 {
fill: var(--banner-text-color);
}

path#bannerBG2 {
fill: var(--banner-BG2);
}

/* nav darkmode switch. */
/* Custom styles for the dark mode toggle switch */

input#dark-mode-switch.form-check-input{
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--primary-color);
  box-shadow: var(--primary-color);

}
input#dark-mode-switch.form-check-input:active{
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--primary-color);
  box-shadow: none;
  outline: none;

}

input#dark-mode-switch.form-check-input:checked{
  background-color: var(--primary-color);
  color: var(--background-color);
  border-color: var(--primary-color);
  box-shadow: none;
  outline: none;
}

input#dark-mode-switch.form-check-input:checked:active{
  background-color: var(--primary-color);
  color: var(--background-color);
  border-color: var(--primary-color);
  box-shadow: none;
  outline: none;
}


.footer {
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
  padding: 20px 0;
  
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  padding: 5px 0;
}





.containerHeightIncrease {
  height: 100%; 
  overflow-x: auto;
  
}

/* Ensure the canvas fills its container */
canvas {
  width: 100%;
  height: 200%;
  display: block; 
}
.canvas-container {
  height: 75vh;
}

.border-project{
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--background-color);
}

.btn-primary:hover {
  background-color: var(--background-color);
  scale: 1.05;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-secondary {
  background-color: var(--background-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn-secondary:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
  scale: 1.05;
  border-color: var(--primary-color);
}

.footer-link {
  color: inherit; 
  text-decoration: none; 
}

.footer-link:hover,
.footer-link:focus {
  text-decoration: none; 
  color: inherit; 
}

.primary-text{
  color: var(--primary-color);
}


.subtitle-text {
  color: var(--subtitle-text-color);
  font-family: 'Aldo the Apache', sans-serif;
}

.text-color {
  color: var(--text-color) !important;
}

path.cls-1{
  fill: var(--banner-text-color);
}
.cursor {
  font-weight: 200;
  color: var(--primary-color);
  opacity: 1;
  animation: blink 1s infinite;
}

/* removing arrows from all bootstrap carousels */
.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

/* Adding Product Explination Hyperlexed Element */
.logo-explain {
 height: 500px;
}

#logo {
  position: relative;
  height: 70vh;
  width: auto;
  aspect-ratio: 1000 / 556;
  user-select: none;
  margin: 5vh;
}

#logo > img {
  width: 100%;
  position: absolute;
}


/* mobile media query  */

@media only screen and (max-width: 600px) {
  
  .canvas-container {
    height: 50vh; /* This sets the height to 75% of the viewport height */
    transform-origin: center center;
    transform: scale(0.5);
  }
}


.hyperlink{
  color: var(--primary-color);
  text-decoration: none;
}


