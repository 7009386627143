/* Light theme colors */
:root {
    --primary-color: #e08600;
    --background-color: #e3e3e3;
    --text-color: #2c2c2c;
    --subtitle-text-color: #1b1b1b;
    --footer-background-color: #f8f9fa;
    --footer-text-color: #3d3d3d;
    


    /* SVG BANNER */
    --banner-text-color: #2c2c2c;
    --banner-BG2: #c4c4c4;
  }
  
  /* Dark theme colors */
  [data-theme='dark'] {
    --primary-color: #ff9700;
    --background-color: #141414;
    --subtitle-text-color: #e5e5e5;
    --text-color: #a2a2a2;
    --footer-background-color: #212529;
    --footer-text-color: #a2a2a2;
    

    /* SVG BANNER */
    --banner-text-color: #f2f2f2;
    --banner-BG2: #292929;
  }
  